import React from "react";
import Facebook from "../assets/facebook.svg";
import Instagram from "../assets/instagram.svg";
import Youtube from "../assets/youtube.svg";
import classes from "../styles/media-buttons.module.css";

export default function MediaButtons() {
  return (
    <div className={classes.root}>
      <a
        rel="noreferrer noopener"
        href="https://www.facebook.com/avenuebarberoakbay/"
        aria-label="Facebook site"
        target="_blank"
      >
        <Facebook className={classes.facebook} />
      </a>
      <a
        rel="noreferrer noopener"
        href="https://www.instagram.com/avenuebarberoakbay/?hl=en"
        aria-label="Instagram site"
        target="_blank"
      >
        <Instagram className={classes.instagram} />
      </a>
      <a
        rel="noreferrer noopener"
        href="https://www.youtube.com/channel/UCjc_nxJOC4iZLrNZC3A6nIg?view_as=subscriber"
        aria-label="Youtube site"
        target="_blank"
      >
        <Youtube className={classes.youtube} />
      </a>
    </div>
  );
}
