import { AppBar, Dialog, Slide, Toolbar } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import { Link } from "gatsby";
import Img from "gatsby-image/withIEPolyfill";
import React, { useState } from "react";
import classes from "../styles/topbar.module.css";
import MediaButtons from "./MediaButtons";

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const TopBar = React.forwardRef((props: any, ref: any) => {
  const { data, location } = props;
  const [nav, setNav] = useState(false);

  const openDialog = () => {
    document.body.style.position = "fixed";

    setNav(true);
  };

  const closeDialog = () => {
    document.body.style.position = "static";

    setNav(false);
  };

  return (
    <AppBar
      position="sticky"
      color="inherit"
      className={classes.root}
      ref={ref}
    >
      <Toolbar className={classes.toolbar}>
        {/* {!nav && (
          <a className={classes.callLink} href="tel:778-440-2174">
            Call 778-440-2174
          </a>
        )} */}
        <div className={classes.media}>
          <MediaButtons />
        </div>
        <Link
          to="/"
          className={classes.logoContainer}
          onClick={() => {
            if (location.pathname === "/") {
              closeDialog();
            }
          }}
        >
          <div className={classes.logo}>
            <Img
              alt="Logo"
              fixed={[
                {
                  ...data.mobileLogo.childImageSharp.fixed,
                  media: `(max-width: 767px)`,
                },
                {
                  ...data.desktopLogo.childImageSharp.fixed,
                  media: `(min-width: 768px)`,
                },
              ]}
            />
          </div>
          <div className={classes.desktopLogo}>
            <Img alt="Logo" fixed={data.desktopLogo.childImageSharp.fixed} />
          </div>
        </Link>
        <div className={classes.bookContainer}>
          <Link
            to="/book/"
            className={`${classes.bookButton} ${classes.hideBook}`}
          >
            Book Now
          </Link>
        </div>
        <button
          aria-label="Navigation menu button"
          style={nav ? { zIndex: 1400 } : {}}
          className={`${classes.con} ${nav ? classes.navOpen : ""}`}
          onClick={() => (nav ? closeDialog() : openDialog())}
        >
          <div className={`${classes.bar} ${classes.top}`}></div>
          <div className={`${classes.bar} ${classes.bottom}`}></div>
        </button>
        <Dialog
          open={nav}
          TransitionComponent={Transition}
          disableBackdropClick
          fullScreen
          className={classes.dialog}
          classes={{ paperFullScreen: classes.dialogPaper }}
          onClose={closeDialog}
          BackdropProps={{
            classes: {
              root: classes.modalRoot,
            },
          }}
        >
          <div className={classes.navLinks}>
            <Link to="/" onClick={closeDialog}>
              Home
            </Link>
            <Link to="/about/" onClick={closeDialog}>
              About
            </Link>
            <Link to="/services/" onClick={closeDialog}>
              Services
            </Link>
            <Link to="/photos/" onClick={closeDialog}>
              Photos
            </Link>
            <Link to="/videos/" onClick={closeDialog}>
              Videos
            </Link>
            <a
              href="mailto:avenuebarber2174@gmail.com?subject=Inquiry From Website"
              aria-label="Send an e-mail to avenue barber"
            >
              Contact
            </a>
            <Link
              to="/covid/"
              onClick={closeDialog}
              style={{ color: "rgba(0,0,0,.3)" }}
            >
              COVID-19
            </Link>
            <Link
              to="/book/"
              style={{
                fontSize: "8vw",
                border: "3px solid #000",
                marginTop: 16,
                width: "auto",
                height: "auto",
                padding: "12px 20px",
              }}
              className={classes.bookButton}
              onClick={closeDialog}
            >
              Book Now
            </Link>
            <div className={classes.mediaDialog}>
              <MediaButtons />
            </div>
          </div>
        </Dialog>
        {!nav && (
          <Link
            to="/book/"
            className={`${classes.bookButton} ${classes.hideMobileBook}`}
          >
            Book
          </Link>
        )}
      </Toolbar>
    </AppBar>
  );
});

export default TopBar;
