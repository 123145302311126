import React from "react";
import classes from "../styles/layout.module.css";
import TopBar from "./TopBar";
import { Link } from "gatsby";
import Footer from "./Footer";
import { useScrollTrigger, Slide } from "@material-ui/core";

function HideOnScroll({ children }) {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

function HideCovidOnScroll({ children }) {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={true} direction="left" in={!trigger}>
      {children}
    </Slide>
  );
}

export default function Layout({ children, data, location }) {
  return (
    <div className={classes.root}>
      <HideOnScroll>
        <TopBar data={data} location={location} />
      </HideOnScroll>
      <div className={classes.nav}>
        <ul className={classes.links}>
          <li>
            <Link activeClassName={classes.activeLink} to="/">
              Home
            </Link>
          </li>
          <li>
            <Link activeClassName={classes.activeLink} to="/about/">
              About
            </Link>
          </li>
          <li>
            <Link activeClassName={classes.activeLink} to="/services/">
              Services
            </Link>
          </li>
          <li>
            <Link activeClassName={classes.activeLink} to="/photos/">
              Photos
            </Link>
          </li>
          <li>
            <Link activeClassName={classes.activeLink} to="/videos/">
              Videos
            </Link>
          </li>
          <li>
            <a
              href="mailto:avenuebarber2174@gmail.com?subject=Inquiry From Website"
              aria-label="Send an email to avenue barber"
            >
              Contact
            </a>
          </li>
          <li>
            <Link
              activeClassName={classes.activeLink}
              to="/covid/"
              style={{ color: "rgba(0,0,0,.4)" }}
            >
              COVID-19
            </Link>
          </li>
        </ul>
      </div>
      <hr />
      <div className={classes.content}>{children}</div>
      {/* <HideCovidOnScroll>
        <div className={classes.callContainer} style={{ bottom: 20 }}>
          <a
            href="tel:778-440-2174"
            aria-label="Telephone number link (778) 440-2174"
            className={classes.call}
          >
            CALL
          </a>
        </div>
      </HideCovidOnScroll> */}
      <Footer />
    </div>
  );
}
