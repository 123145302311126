import React from "react";
import classes from "../styles/footer.module.css";
import MediaButtons from "./MediaButtons";

export default function Footer() {
  return (
    <div className={classes.root}>
      <hr />
      <h3>Victoria</h3>
      {/* <p className={classes.holiday}>
        Holiday Hours <span className={classes.pipe}>|</span> We will be closed
        from December 25th until January 4th!
      </p> */}
      <p>
        Sun - Mon <span className={classes.pipe}>|</span> Closed
      </p>
      <p>
        Tue - Fri <span className={classes.pipe}>|</span> 9am - 5pm
      </p>
      <p>
        Sat <span className={classes.pipe}>|</span> 9am - 4pm
      </p>
      <a
        href="https://goo.gl/maps/YmYPeiTvTgQ2WtXD7"
        aria-label="Google maps link for   2174 Oak Bay Ave. Victoria, BC V8R 1E9"
        rel="noreferrer noopener"
        target="_blank"
      >
        2174 Oak Bay Ave. Victoria, BC V8R 1E9
      </a>
      <a
        href="tel:778-440-2174"
        aria-label="Telephone number link (778) 440-2174"
        style={{ marginBottom: "3.3rem", fontSize: "1rem" }}
        target="_blank"
        rel="noreferrer"
      >
        (778) 440-2174
      </a>
      <MediaButtons />
      <hr />
      <p style={{ fontSize: "0.7rem", marginBottom: 48 }}>
        &copy; Avenue Barber
      </p>
    </div>
  );
}
